import React from "react";
import SbEditable from "storyblok-react";
import StoryblokService from "../utils/storyblok-service";
import DynamicComponent from "../sb-components/dynamicComponent";
import {
  ButtonLargeStoryblok,
  FooterLinksStoryblok,
  HeaderSectionStoryblok,
  HubSpotFormStoryblok,
  ImageLinkItemStoryblok,
  LinkItemStoryblok,
  TypographyGeneralStoryblok,
  TypographyLinkStoryblok,
} from "../types";

/* ----- Types ----- */
type GlobalPageHeaderType = {
  component: string;
  linksBlock: LinkItemStoryblok[];
  promotionalBlock: TypographyGeneralStoryblok[];
  promotionalBlockMobile: TypographyLinkStoryblok[];
  promotionalLink: TypographyLinkStoryblok[];
  sectionsBlock: HeaderSectionStoryblok[];
  showOn: any;
  show_promotional: boolean;
  _editable: string;
  _uid: string;
};

type GlobalPageFooterType = {
  bottomLinks: LinkItemStoryblok[];
  component: string;
  copyright: TypographyGeneralStoryblok[];
  emailInputPlaceholder: string;
  loginButton: ButtonLargeStoryblok[];
  mainLinks: FooterLinksStoryblok[];
  socialLinks: ImageLinkItemStoryblok[];
  subscription: HubSpotFormStoryblok[];
  title: TypographyGeneralStoryblok[];
  _editable: string;
  _uid: string;
};

type State = {
  story: Object;
};

type Props = {};

/* ----- Main Component ----- */
class GlobalPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { story: {} };
  }

  async componentDidMount() {
    const story = await this.getInitialStory();
    if (story.content) {
      this.setState({ story });
    }
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }

  async getInitialStory() {
    const {
      data: { story },
    } = await StoryblokService.get(`cdn/stories/global`);
    return story;
  }

  render() {
    const { content } = this.state.story as any;

    return content ? (
      <SbEditable content={content}>
        {content.header &&
          (content.header as GlobalPageHeaderType[]).map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}

        <h2 style={{ textAlign: "center", padding: "20px", opacity: ".3" }}>
          PAGE CONTENT
        </h2>

        {content.footer &&
          (content.footer as GlobalPageFooterType[]).map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}
      </SbEditable>
    ) : null;
  }
}

export default GlobalPage;
